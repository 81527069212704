import React, { createContext, useReducer, useContext } from 'react';
import { IMemorialContext } from './memorial.types';
import { memorialReducer } from './memorial.reducer';
import { getLanguageFromNavigator } from '../utils';

export const initialState = {
  email: '',
  expiredSession: false,
  firstName: '',
  hasLockedTheApp: false,
  id: '',
  image: '',
  initialToken: '',
  isFirstTime: null,
  isUserLoggedIntoSiteUnderConstruction: false,
  language: getLanguageFromNavigator(),
  lastName: '',
  phone: '',
  phoneCode: '',
  products: {
    animateImage: { id: '', currency: '' },
    basic: { id: '', currency: '' },
    premium: { id: '', currency: '' },
  },
  signUpEmail: '',
  surname: '',
  urlToManagePaymentMethod: '',
  username: '',
};

export const MemorialContext = createContext<{
  memorialState: IMemorialContext;
  memorialDispatch: React.Dispatch<any>;
}>({
  memorialState: initialState,
  memorialDispatch: () => null,
});

const MemorialProvider = ({ children }: { children: React.ReactNode | undefined }) => {
  const [memorialState, memorialDispatch] = useReducer(memorialReducer, initialState);

  return (
    <MemorialContext.Provider value={{ memorialState, memorialDispatch }}>
      {children}
    </MemorialContext.Provider>
  );
};
function useMemorial() {
  const { memorialState, memorialDispatch } = useContext(MemorialContext);
  if (memorialState === undefined) {
    throw new Error('memorialState error');
  }
  if (memorialDispatch === undefined) {
    throw new Error('memorialDispatch error');
  }
  return { ...memorialState, memorialDispatch };
}

export { MemorialProvider, useMemorial };
