import { Button } from 'antd';
import logoBlue from '../../assets/images/logoBlueNegative.svg';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Login from '../../components/Login';
import { useTranslation } from 'react-i18next';
import Menu from './Menu';
import LanguageSelector from './LanguageSelector';
import { useMemorial } from '../../context/memorial.provider';
import { useEffect, useState } from 'react';
import { useIsMobile } from '../../utils/hooks';
import { goToWPHome } from '../../utils';
import useStyle from './index.styles';

const Topbar = () => {
  const [searchParams] = useSearchParams();
  const [showLoginModalAutomatically, setShowLoginModalAutomatically] = useState<boolean>(
    searchParams.has('showLogin')
  );
  const { hasLockedTheApp, id } = useMemorial();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyle();

  useEffect(() => {
    if (searchParams.has('showLogin')) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('showLogin');
      navigate({ pathname: location.pathname, search: searchParams.toString() }, { replace: true });
    }
  }, [location.pathname, location.search, navigate, searchParams]);

  const getClassName = (path: string) => {
    if (location.pathname === path) {
      return 'text-primary';
    }
    return 'text-text1';
  };

  return (
    <>
      {isMobile && (
        <Menu
          setShowLoginModalAutomatically={setShowLoginModalAutomatically}
          showLoginModalAutomatically={showLoginModalAutomatically}
        />
      )}
      {!isMobile && (
        <div
          className={`${classes.topBarDesktop} z-10 box-border flex min-h-[80px] items-center justify-between bg-white px-[24px] py-[16px] text-text5 shadow-down`}
        >
          <img
            onClick={() => navigate(id ? '/home/start?page=1&pageSize=9' : '/')}
            src={logoBlue}
            alt=""
            className="cursor-pointer"
          />
          <div className="flex items-center">
            {id ? (
              <>
                <Button onClick={goToWPHome} className="mr-[32px]" type="primary">
                  {t('common.home')}
                </Button>
                <Button
                  onClick={() => navigate('/home/start?page=1&pageSize=9')}
                  ghost
                  className={`${getClassName('/home/start')} mr-[32px] px-[0px]`}
                  disabled={hasLockedTheApp}
                >
                  {t('start.exploreMemorials')}
                </Button>
                <Button
                  onClick={() => navigate('/home/memorials/list?page=1')}
                  ghost
                  className={`${getClassName('/home/memorials/list')} mr-[32px] px-[0px]`}
                  disabled={hasLockedTheApp}
                >
                  {t('topbar.myMemorials')}
                </Button>
                <Button
                  ghost
                  className={`${getClassName(
                    '/home/memorials/comments-management'
                  )} mr-[32px] px-[0px]`}
                  onClick={() => navigate('/home/memorials/comments-management')}
                  disabled={hasLockedTheApp}
                >
                  {t('topbar.commentsManagement')}
                </Button>
                <Button
                  ghost
                  className={`${getClassName('/home/facebook-profile')} mr-[24px] px-[0px]`}
                  onClick={() => navigate('/home/facebook-profile')}
                  disabled={hasLockedTheApp}
                >
                  {t('topbar.facebookProfileManagement')}
                </Button>
                <Menu />
                <div className="ml-[8px]">
                  <LanguageSelector />
                </div>
              </>
            ) : (
              <>
                <Button onClick={goToWPHome} type="primary">
                  {t('common.home')}
                </Button>
                <Button ghost className="mx-[8px] text-text1" onClick={() => navigate('/')}>
                  {t('common.start')}
                </Button>
                <Button
                  onClick={() => navigate('/home/memorials/new')}
                  className="secondary mr-[8px]"
                >
                  {t('newMemorial.registerMemorial')}
                </Button>
                <Login
                  onCloseModal={() => setShowLoginModalAutomatically(false)}
                  showModal={showLoginModalAutomatically}
                />
                <div className="ml-[8px]">
                  <LanguageSelector />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Topbar;
