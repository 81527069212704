import update from 'immutability-helper';
import { IMemorialContext } from './memorial.types';
import { MemorialActionType } from './memorial.actions.types';
import * as memorialTypes from './memorial.actions.types';
import { initialState } from './memorial.provider';

export const memorialReducer = (state: IMemorialContext, action: MemorialActionType) => {
  switch (action.type) {
    case memorialTypes.GET_TOKEN:
      return {
        ...state,
        initialToken: action.payload,
      };
    case memorialTypes.GET_DECODED:
      return {
        ...state,
        roles: action.payload.roles,
        id: action.payload.id,
      };

    case memorialTypes.SET_EXPIRED_SESSION: {
      return update(state, {
        $merge: {
          ...initialState,
          expiredSession: action.payload.value,
        },
      });
    }

    case memorialTypes.LOGOUT: {
      return initialState;
    }

    case memorialTypes.UPDATE_USER_INFORMATION: {
      const {
        email,
        firstName,
        image,
        language,
        lastName,
        phone,
        phoneCode,
        urlToManagePaymentMethod,
      } = action.payload;

      return update(state, {
        $merge: {
          email,
          firstName,
          language,
          lastName,
          phone,
          image,
          phoneCode,
          ...(urlToManagePaymentMethod ? { urlToManagePaymentMethod } : {}),
        },
      });
    }

    case memorialTypes.SET_USER_LOGGED_INTO_SITE_UNDER_CONSTRUCTION: {
      return update(state, {
        isUserLoggedIntoSiteUnderConstruction: {
          $set: true,
        },
      });
    }

    case memorialTypes.SET_PRODUCTS: {
      const { products } = action.payload;

      return update(state, {
        products: {
          $set: products,
        },
      });
    }

    case memorialTypes.LOCK_APP: {
      return update(state, {
        hasLockedTheApp: { $set: true },
      });
    }

    default: {
      return state;
    }
  }
};
