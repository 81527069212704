import { SupportedLanguage } from '../types/SupportedLanguage';
import { Products } from './memorial.types';

export const GET_DECODED = 'GET_DECODED';
export const GET_TOKEN = 'GET_TOKEN';
export const LOCK_APP = 'LOCK_APP';
export const LOGOUT = 'LOGOUT';
export const SET_CURRENCIES = 'SET_CURRENCIES';
export const SET_EXPIRED_SESSION = 'SET_EXPIRED_SESSION';
export const SET_PATIENT_CONFIG = 'SET_PATIENT_CONFIG';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PROVIDER_GROUPS = 'SET_PROVIDER_GROUPS';
export const SET_USER_LOGGED_INTO_SITE_UNDER_CONSTRUCTION =
  'SET_USER_LOGGED_INTO_SITE_UNDER_CONSTRUCTION';
export const SIGNUP_EMAIL = 'SIGNUP_EMAIL';
export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';

export interface GetToken {
  type: typeof GET_TOKEN;
  payload: string;
}

export interface GetDecodedToken {
  type: typeof GET_DECODED;
  payload: {
    id: string;
    roles: string[];
  };
}

export interface SetExpiredSessionAction {
  type: typeof SET_EXPIRED_SESSION;
  payload: {
    value: boolean;
  };
}

export interface Logout {
  type: typeof LOGOUT;
}

export interface UpdateUserInformationAction {
  type: typeof UPDATE_USER_INFORMATION;
  payload: {
    email: string;
    firstName: string;
    image: string;
    language: SupportedLanguage;
    lastName: string;
    phone: string;
    phoneCode: string;
    urlToManagePaymentMethod?: string;
  };
}

export interface SetUserLoggedIntoSiteUnderConstruction {
  type: typeof SET_USER_LOGGED_INTO_SITE_UNDER_CONSTRUCTION;
  payload: {};
}

export interface SetProducts {
  type: typeof SET_PRODUCTS;
  payload: { products: Products };
}

export interface LockApp {
  type: typeof LOCK_APP;
  payload: {};
}

export type MemorialActionType =
  | GetDecodedToken
  | GetToken
  | LockApp
  | Logout
  | SetExpiredSessionAction
  | SetProducts
  | SetUserLoggedIntoSiteUnderConstruction
  | UpdateUserInformationAction;
